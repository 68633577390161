import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import classNames from 'classnames/bind'
import { useLocation } from '@reach/router'

import WistiaControls from './WistiaControls'

import s from './WistiaEmbed.module.scss'
const cn = classNames.bind(s)

const defaultOptions = {
  // PLAYBACK
  preload: true,
  muted: false,
  playsinline: true,
  autoPlay: false,
  silentAutoPlay: false,
  endVideoBehavior: 'default',
  fakeFullscreen: false,

  // LAYOUT
  fitStrategy: 'contain',
  videoFoam: false,
  // wmode: 'transparent',

  // TRACKING
  copyLinkAndThumbnailEnabled: false,
  doNotTrack: true,
  googleAnalytics: false,
  seo: false,

  // QUALITY
  // qualityMax,
  // qualityMin,

  // UI CONTROLS
  controlsVisibleOnLoad: false,
  fullscreenOnRotateToLandscape: false,
  fullscreenButton: false,
  settingsControl: false,
  qualityControl: false,
  playButton: false,
  playbar: false,
  playbackRateControl: false,
  smallPlayButton: false,
}

const play = (el) => {
  if (typeof el.play !== 'function') return
  return el.play()
}

const pause = (el) => {
  if (typeof el.pause !== 'function') return
  return el.pause()
}

const WistiaEmbed = ({ id, controls = true, options: _options, style, initialState, readyCallback = null }) => {
  const { key } = useLocation()
  const [isReady, setIsReady] = useState(false)
  const videoRef = useRef(null)
  const wrapperRef = useRef(null)

  const options = { ...defaultOptions, ..._options }

  useEffect(() => {
    if (videoRef.current) return

    // ready to play, has data and is embedded
    const onReady = (video) => {
      videoRef.current = video
      setIsReady(true)
      readyCallback?.(video)
    }

    window._wq = [...(window._wq || []), (w) => w.consent(false), { id, options, onReady }]
  }, [id, videoRef])

  // bind events if needed
  useEffect(() => {
    if (!videoRef.current) return
    return () => {
      videoRef.current.remove() // clean up when switching page (IMPORTANT)
    }
  }, [key, id, videoRef])

  useEffect(() => {
    if (!videoRef.current || !isReady) return
    if (!initialState || videoRef.current.state() === initialState) return
    if (initialState === 'playing') play(videoRef.current)
    if (initialState === 'paused') pause(videoRef.current)
  }, [isReady, initialState, videoRef.current])

  // We get weird errors when rendering from the server - let's skip
  const isSSR = typeof window === 'undefined'
  if (isSSR) return null

  return (
    <>
      <Helmet>
        <script src={`https://fast.wistia.com/embed/medias/${id}.jsonp`} async></script>
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
      </Helmet>
      <div className={s.wrapper} style={style} ref={wrapperRef}>
        <div className={cn('WistiaEmbed', `wistia_embed wistia_async_${id}`)} />
        {controls && (
          <WistiaControls video={videoRef.current} wrapperRef={wrapperRef} initialStateMuted={options.silentAutoPlay} />
        )}
      </div>
    </>
  )
}

WistiaEmbed.propTypes = {
  id: PropTypes.string,
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
  options: PropTypes.object,
  style: PropTypes.object,
  initialState: PropTypes.oneOf(['playing', 'paused']),
  readyCallback: PropTypes.func,
}

export default WistiaEmbed
