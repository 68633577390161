import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import MuteIcon from 'assets/svg/video-controls-mute.inline.svg'
import UnmuteIcon from 'assets/svg/video-controls-unmute.inline.svg'
import ExpandIcon from 'assets/svg/video-controls-expand.inline.svg'
import ShrinkIcon from 'assets/svg/video-controls-shrink.inline.svg'

import useUIContext from 'context/ui'
import useFullscreen from 'lib/useFullscreen'
import Cursor, { CURSOR_ICONS } from 'components/ui/Cursor'
import CursorHover from 'components/ui/CursorHover'

import s from './WistiaControls.module.scss'
const cn = classNames.bind(s)

// if we need to reuse this ui, this should probably be separated into:
// 1. a hook that abstracts all the logic/state/side effects and return handlers and state
// 2. a dumb component that could plug either on wistia or on html5 video (or anything else that exposes the same hook api)

const {
  VIDEO_CONTROLS_MUTE,
  VIDEO_CONTROLS_UNMUTE,
  VIDEO_CONTROLS_PAUSE,
  VIDEO_CONTROLS_PLAY,
  VIDEO_CONTROLS_EXPAND,
  VIDEO_CONTROLS_SHRINK,
} = CURSOR_ICONS

const getMuteIcon = (isMuted) => {
  if (isMuted === true) return VIDEO_CONTROLS_MUTE
  if (isMuted === false) return VIDEO_CONTROLS_UNMUTE
  return null
}

const getPlayIcon = (isPlaying) => {
  if (isPlaying === true) return VIDEO_CONTROLS_PAUSE
  if (isPlaying === false) return VIDEO_CONTROLS_PLAY
  return null
}

const getFullscreenIcon = (isFullscreen) => {
  if (isFullscreen === true) return VIDEO_CONTROLS_SHRINK
  if (isFullscreen === false) return VIDEO_CONTROLS_EXPAND
  return null
}

const WistiaControls = ({ video, wrapperRef, initialStateMuted = false }) => {
  // states
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(initialStateMuted)
  const [isFullscreen, setIsFullscreen, supportsFullscreen] = useFullscreen(wrapperRef)
  // const [volume, setVolume] = useState(initialStateMuted ? 0 : 1)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const cursorIcon = useUIContext((s) => s.cursorIcon)
  const setCursorIcon = useUIContext((s) => s.setCursorIcon)

  // handlers
  const play = () => video?.play?.()
  const pause = () => video?.pause?.()

  const togglePlay = () => {
    if (video?.state() === 'ended') {
      video.time(0)
      return play()
    }
    if (video?.state() === 'playing') return pause()
    if (video?.state() === 'paused') return play()
  }

  const toggleMute = () => (isMuted ? video?.unmute?.() : video?.mute?.())
  const seekTo = (v) => video?.time?.(v)

  // const volumeChangeTo = (v) => {
  //   video?.unmute?.()
  //   video?.volume?.(v)
  // }

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen)
  }

  // side-effects
  useEffect(() => {
    if (!video) return

    // initial state
    setDuration(video.duration())
    setIsPlaying(video.state() === 'playing')
    setCurrentTime(video.time())
    setIsMuted(video.isMuted())

    // callbacks
    const onPlay = () => setIsPlaying(true)

    const onPause = () => setIsPlaying(false)

    const onEnd = () => setIsPlaying(false)

    const onVolumeChange = (v, m) => {
      // setVolume(v)
      setIsMuted(m)
    }

    // bindings
    video.bind('play', onPlay)
    video.bind('pause', onPause)
    video.bind('end', onEnd)
    video.bind('mutechange', setIsMuted)
    video.bind('timechange', setCurrentTime)
    video.bind('volumechange', onVolumeChange)
    return () => {
      video.unbind('play', onPlay)
      video.unbind('pause', onPause)
      video.unbind('end', onEnd)
      video.unbind('mutechange', setIsMuted)
      video.unbind('timechange', setCurrentTime)
      video.unbind('volumechange', onVolumeChange)
    }
  }, [video])

  // side-effect to toggle mute button
  useEffect(() => {
    if (isMuted === null || !cursorIcon) return
    if (cursorIcon !== VIDEO_CONTROLS_MUTE && cursorIcon !== VIDEO_CONTROLS_UNMUTE) return
    setCursorIcon(getMuteIcon(isMuted))
  }, [isMuted, cursorIcon, setCursorIcon])

  // side-effect to toggle play button
  useEffect(() => {
    if (isPlaying === null || !cursorIcon) return
    if (cursorIcon !== VIDEO_CONTROLS_PAUSE && cursorIcon !== VIDEO_CONTROLS_PLAY) return
    setCursorIcon(getPlayIcon(isPlaying))
  }, [isPlaying, cursorIcon, setCursorIcon])

  // side-effect to toggle play button
  useEffect(() => {
    if (isFullscreen === null || !cursorIcon) return
    if (cursorIcon !== VIDEO_CONTROLS_SHRINK && cursorIcon !== VIDEO_CONTROLS_EXPAND) return
    setCursorIcon(getFullscreenIcon(isFullscreen))
  }, [isFullscreen, cursorIcon, setCursorIcon])

  return (
    <>
      <div className={cn('wrapper', { isVisible: true })}>
        <CursorHover icon={getPlayIcon(isPlaying)}>
          <button className={cn('playButton')} aria-label={isPlaying ? 'pause' : 'play'} onClick={togglePlay} />
        </CursorHover>
        <div className={cn('controls')} onClick={(e) => e.stopPropagation()}>
          <div className={cn('controlsContainer')}>
            <div className={cn('volumeControls')}>
              <CursorHover icon={getMuteIcon(isMuted)}>
                <label className={cn('controlButton')} onChange={toggleMute}>
                  <input type="checkbox" value={isMuted ?? false} />
                  {isMuted ? <UnmuteIcon /> : <MuteIcon />}
                </label>
              </CursorHover>
              {/* <div className={cn('volumeProgress', { isVisible: true })}>
                <progress className={cn('progress')} min={0} max={1} step={0.01} value={isMuted ? 0 : volume ?? 0} />
                <input
                  className={cn('range')}
                  type="range"
                  min={0}
                  max={1}
                  step={0.01}
                  value={volume ?? 0}
                  onChange={({ target }) => volumeChangeTo(parseFloat(target.value), false)}
                />
              </div> */}
            </div>
            <div className={cn('seekProgress', { isVisible: isFinite(duration) })}>
              <progress
                className={cn('progress')}
                min={0}
                max={Math.max(duration, currentTime) || 1}
                step={0.01}
                value={currentTime ?? 0}
              />
              <input
                className={cn('range')}
                type="range"
                min={0}
                max={Math.max(duration, currentTime) || 1}
                step={0.01}
                value={currentTime ?? 0}
                onChange={({ target }) => seekTo(parseFloat(target.value))}
                onMouseDown={pause}
                onMouseUp={play}
              />
            </div>
            {!!supportsFullscreen && (
              <div className={cn('fullscreenControls')}>
                <CursorHover icon={getFullscreenIcon(isFullscreen)}>
                  <button className={cn('controlButton')} onClick={toggleFullscreen} aria-label="fullscreen">
                    {isFullscreen ? <ShrinkIcon /> : <ExpandIcon />}
                  </button>
                </CursorHover>
              </div>
            )}
          </div>
        </div>
      </div>
      {isFullscreen && <Cursor portaled={false} />}
    </>
  )
}

WistiaControls.propTypes = {
  video: PropTypes.object,
  wrapperRef: PropTypes.object,
  initialStateMuted: PropTypes.bool,
}

export default WistiaControls
