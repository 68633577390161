import { useLayoutEffect, useState } from 'react'
import screenfull from 'screenfull'

const useFullscreen = (ref) => {
  const [isFullscreen, setIsFullscreen] = useState(screenfull.isFullscreen)

  const setFullscreen = (next) => {
    if (!screenfull.isEnabled) return
    if (!ref.current || next === isFullscreen) return

    if (!next) return screenfull.exit()
    return screenfull.request(ref.current)
  }

  useLayoutEffect(() => {
    if (!screenfull.isEnabled) return
    const onFullscreen = () => {
      const is = screenfull.isFullscreen
      setIsFullscreen(is)
      document.documentElement.setAttribute('data-fullscreen', is)
    }
    screenfull.on('change', onFullscreen)
    return () => {
      screenfull.off('change', onFullscreen)
    }
  }, [screenfull.isEnabled])

  return [isFullscreen, setFullscreen, screenfull.isEnabled]
}
export default useFullscreen
